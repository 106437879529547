import { ItemCounters, parseXOMembershipToken } from '@/utils/pages'
import { getCartItemCount } from '@/modules/view-model/views/product-landing/api'
import { getCartDraftsCounters, getDraftItemCount } from '@/services/storefront/drafts/drafts'
import { ItemCounterType } from '.'
import { getClientHeaders } from '@/utils/helpers'

export type Action =
  | { type: 'cart'; response: ItemCounters }
  | { type: 'drafts'; response: ItemCounters }
  | { type: 'all'; response: ItemCounters }
  | { type: 'initialProps'; response: ItemCounters }

const defaultCounters = { cart: 0, drafts: 0 }

export function refetchItemCountersReducer(currentItemCounters: ItemCounters, action: Action): ItemCounters {
  switch (action.type) {
    case 'cart': {
      return { ...currentItemCounters, cart: action.response.cart }
    }
    case 'drafts': {
      return { ...currentItemCounters, drafts: action.response.drafts }
    }
    case 'all': {
      return { ...currentItemCounters, ...action.response }
    }
    case 'initialProps': {
      return action.response || defaultCounters
    }
  }
}

export async function getItemCount(itemCounterType: ItemCounterType): Promise<ItemCounters> {
  const headers = getClientHeaders()

  switch (itemCounterType) {
    case 'cart': {
      const response = await getCartItemCount(headers)
      return { ...defaultCounters, cart: response }
    }
    case 'drafts': {
      const response = await getDraftItemCount(headers)
      return { ...defaultCounters, drafts: response }
    }
    case 'all': {
      const isLoggedIn = !!parseXOMembershipToken(document.cookie)
      return getCartDraftsCounters(isLoggedIn, headers)
    }
  }
}
