const HOST = String(process.env.APP_PAPER_HOST)

export const loginRoute = HOST + '/users-login.php'

export enum AuthOptions {
  login = 'login',
  signUp = 'signUp',
}

export const login = [
  {
    id: AuthOptions.signUp,
    content: 'Join now',
    href: HOST + '/users-signup.php',
    redirectPath: 'redirect',
  },
  {
    id: AuthOptions.login,
    content: 'Log in',
    href: loginRoute,
    redirectPath: 'r',
  },
]

const invitationsNavigationLinksData = [
  {
    content: 'Wedding Invitations',
    href: HOST + '/wedding-paper/wedding-invitations',
  },
  {
    content: 'Save the Dates',
    href: HOST + '/wedding-paper/save-the-date-cards',
  },
  {
    content: 'Change the Dates',
    href: HOST + '/wedding-paper/change-the-date-cards',
  },
  {
    content: 'Ceremony & Reception',
    href: HOST + '/wedding-paper/ceremony-reception',
  },
  {
    content: 'Thanks Yous',
    href: HOST + '/wedding-paper/thank-yous',
  },
  {
    content: 'Get a Free Sample Kit',
    href: HOST + '/wedding-paper/page/free-wedding-invitation-samples',
    colorPrimary: true,
  },
]

export const navigationLinksData = [
  {
    content: 'Planning Tools',
    href: HOST + '/wedding-planning.html',
  },
  {
    content: 'Venues',
    href: HOST + '/wedding-venues',
  },
  {
    content: 'Vendors',
    href: HOST + '/wedding-vendors',
  },
  {
    content: 'Forums',
    href: HOST + '/wedding-forums',
    mutableDesktop: true,
    mutableTablet: true,
  },
  {
    content: 'Dresses',
    href: HOST + '/wedding-photos/dresses',
  },
  {
    content: 'Ideas',
    href: HOST + '/wedding-ideas',
  },
  {
    content: 'Registry',
    href: HOST + '/wedding-registry/manage',
  },
  {
    content: 'Wedding website',
    href: HOST + '/wedding-websites.html',
    mutableTablet: true,
  },
  {
    content: 'Invitations',
    href: HOST + '/wedding-paper',
    submenu: invitationsNavigationLinksData,
  },
  {
    content: 'Shop',
    href: 'https://www.weddingwireshop.com?utm_source=weddingwire&utm_medium=referral&utm_campaign=Shop_Nav',
    target: '_blank',
  },
]

export const navigationFooterLinksData = [
  {
    content: 'WeddingWire Planning App',
    href: HOST + '/app-download.php',
    bordered: true,
    primary: true,
  },
  {
    content: 'WeddingWire Guest App',
    href: HOST + '/guests/app',
    primary: true,
  },
  {
    content: 'Help',
    href: 'https://support.weddingwire.com/hc/en-us',
    target: '_blank',
    bordered: true,
  },
  {
    content: 'Privacy Policy',
    href: HOST + '/corp/legal/privacy-policy',
  },
  {
    content: 'Term of use',
    href: HOST + '/corp/legal/terms-of-use',
  },
  {
    content: 'Do not sell my info',
    href: HOST + '/privacy/noSaleForm',
  },
  {
    content: 'Are you a vendor?',
    href: HOST + '/vendors/login',
  },
  {
    content: 'Find a couple’s website',
    href: 'https://www.weddingwire.us/',
  },
]
