import { ComponentType, createContext, PropsWithChildren, useCallback, useContext, useState } from 'react'
import { isTenant } from '@/utils/config'
import OverlaySpinnerWW from '@/modules/shared/BrandedOverlaySpinner/ww/OverlaySpinnerWW'
import OverlaySpinnerTK from '@/modules/shared/BrandedOverlaySpinner/tk/OverlaySpinnerTK'
import dynamic from 'next/dynamic'

const OverlayModal = dynamic(() =>
  import('@/modules/shared/BrandedOverlaySpinner/OverlayModal').then((m) => m.OverlayModal)
)

const BrandedOverlaySpinner = isTenant('ww') ? OverlaySpinnerWW : OverlaySpinnerTK

interface OverlaySpinnerContext {
  showOverlay: (element?: JSX.Element | null) => void
  hideOverlay: () => void
}

const OverlaySpinnerContext = createContext<OverlaySpinnerContext>({
  showOverlay: () => {},
  hideOverlay: () => {},
})

export const OverlaySpinnerProvider: ComponentType<PropsWithChildren<unknown>> = ({ children }) => {
  const [isShown, setIsShown] = useState(false)
  const [element, setElement] = useState<JSX.Element | null>(null)

  const showOverlay = useCallback(
    (element?: JSX.Element | null) => {
      setIsShown(true)

      if (element) {
        setElement(element)
      }
    },
    [setElement, setIsShown]
  )

  const hideOverlay = useCallback(() => {
    setIsShown(false)
    setElement(null)
  }, [setElement, setIsShown])

  let content = <BrandedOverlaySpinner />

  if (element) {
    content = <OverlayModal>{element}</OverlayModal>
  }

  return (
    <OverlaySpinnerContext.Provider value={{ showOverlay, hideOverlay }}>
      {isShown && content}
      {children}
    </OverlaySpinnerContext.Provider>
  )
}

export const useOverlaySpinner = () => {
  const context = useContext(OverlaySpinnerContext)

  if (!context) {
    throw new Error('useOverlaySpinner must be used within OverlaySpinnerProvider')
  }

  return context
}
