import Overlay from '@xo-union/tk-component-overlay'
import Spinner from '@xo-union/tk-component-spinner'

import styles from './overlaySpinnerTK.module.scss'

const OverlaySpinner = () => (
  <Overlay className={styles.overlay} color="light">
    <Spinner className={styles.overlaySpinner} />
  </Overlay>
)

export default OverlaySpinner
