// FOUNDATION - Remove when integrating Cart page
//TODO: MUST BE REMOVED??

export const CartItemCount = /* GraphQL */ `
  query CartItemCount {
    cart {
      itemCount
    }
  }
`

export const AddItemToCart = /* GraphQL */ `
  mutation AddItemToCart($input: AddItemToCartInput!) {
    cart {
      addItem(input: $input) {
        cart {
          totalAmount
          storeCartId
          items {
            productName
            quantity
            totalAmount
            id
          }
        }
        item {
          productName
          id
          totalAmount
          quantity
          portfolioItem {
            id
          }
        }
      }
    }
  }
`

export const RemoveFromCart = /* GraphQL */ `
  mutation RemoveFromCart($input: RemoveFromCartInput!) {
    cart {
      removeItems(input: $input) {
        cart {
          totalAmount
          items {
            productName
            quantity
            totalAmount
            id
          }
        }
      }
    }
  }
`

export const UpdateCart = /* GraphQL */ `
  mutation UpdateCart($input: UpdateCartItemInput!) {
    cart {
      updateItems(input: $input) {
        cart {
          totalAmount
        }
        items {
          id
          quantity
          totalAmount
        }
      }
    }
  }
`
