import { Credentials } from './MemberModal'

const paperUserMarketingOptins = {
  TKThirdPartyInfo: true,
  TKStagesNews: true,
  TKKnotNews: true,
  TKNewsflashNews: true,
  TKKnotShopNews: true,
}

export const parseMembershipModalError = (error: any): string => {
  try {
    const errorObject = error.body ? error.body.errors[0] : error.response.body.errors[0]
    const errorMessage = errorObject.field ? `${errorObject.field} ${errorObject.detail}` : errorObject.detail
    return errorMessage as string
  } catch (error) {
    return 'Oops ! A problem occurred'
  }
}

export const createSignupOptions = (email: string, password: string, userAction: string) => ({
  email: email,
  password: password,
  brand: 'theknot',
  application: 'paper',
  action: userAction,
  optins: paperUserMarketingOptins,
})

export const extractCredentials = (data: any): Credentials => ({
  email: data.email ?? data.data.email,
  password: data.password ?? data.data.password,
})

export type MembershipAuthIntent = 'LOG_IN' | 'SIGN_UP' | null

type MembershipAuthIntentType = {
  logIn: MembershipAuthIntent
  signUp: MembershipAuthIntent
}
export const MembershipAuthIntents: MembershipAuthIntentType = {
  logIn: 'LOG_IN',
  signUp: 'SIGN_UP',
}
