import {
  ComponentType,
  createContext,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react'
import { ItemCounters } from '@/utils/pages'
import { NotificationMessageProps } from '../../components/NotificationToast/NotificationToast/NotificationMessage/NotificationMessage'
import { getItemCount, refetchItemCountersReducer } from './ItemNoticeReducer'

export type ItemNoticeStateProps = {
  displayNotificationMessage: boolean
  content: ReactElement<NotificationMessageProps> | null
  noticeType?: 'success' | 'error' | 'warning'
  shouldAutoClose: boolean
}
export type ItemNoticeContextProps = {
  itemNoticeState: ItemNoticeStateProps
  setItemNoticeState: (callback: (prev: ItemNoticeStateProps) => ItemNoticeStateProps) => void
  itemCountersState: ItemCounters
  refetchItemCounters: (itemCounterType: ItemCounterType) => Promise<void>
}
export type ItemNoticeProviderProps = { children: ReactNode; itemCounters: ItemCounters }
export type ItemCounterType = 'cart' | 'drafts' | 'all'
const itemNoticeInitialState = {
  displayNotificationMessage: false,
  content: null,
  shouldAutoClose: true,
}

export const ItemNoticeContext = createContext<ItemNoticeContextProps | undefined>(undefined)

export const ItemNoticeProvider: ComponentType<PropsWithChildren<ItemNoticeProviderProps>> = ({
  children,
  itemCounters,
}: ItemNoticeProviderProps) => {
  const [itemCountersState, dispatch] = useReducer(refetchItemCountersReducer, itemCounters)
  const [itemNoticeState, setItemNoticeState] = useState<ItemNoticeStateProps>(itemNoticeInitialState)

  useEffect(() => {
    dispatch({ type: 'initialProps', response: itemCounters })
  }, [itemCounters])

  async function refetchItemCounters(itemCounterType: ItemCounterType) {
    const response = await getItemCount(itemCounterType)
    dispatch({ type: itemCounterType, response })
  }

  const { displayNotificationMessage, shouldAutoClose } = itemNoticeState

  useEffect(() => {
    if (displayNotificationMessage && shouldAutoClose) {
      const notificationTimeout = 4000

      const notificationTimer = setTimeout(
        () =>
          setItemNoticeState((prev) => ({
            ...prev,
            displayNotificationMessage: false,
          })),
        notificationTimeout
      )

      return () => clearTimeout(notificationTimer)
    }
  }, [displayNotificationMessage, setItemNoticeState])

  return (
    <ItemNoticeContext.Provider value={{ itemNoticeState, setItemNoticeState, itemCountersState, refetchItemCounters }}>
      {children}
    </ItemNoticeContext.Provider>
  )
}

export const useItemNoticeContext = () => {
  const context = useContext(ItemNoticeContext)

  if (context === undefined) {
    throw new Error('useItemNoticeContext must be used within a ItemNoticeProvider')
  }

  return context
}
