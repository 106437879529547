import { useContext } from 'react'
import { TrackingContext } from '../TrackingProvider/TrackingProvider'

export function useTracking() {
  const ctx = useContext(TrackingContext)

  if (!ctx) {
    throw new Error('useTracking must be used within TrackingProvider')
  }

  return ctx
}
