const isValidUrl = (s: string) => {
  try {
    new URL(s)
    return true
  } catch (e) {
    return false
  }
}

export const getUrlToExternalApp = (path: string) => {
  if (isValidUrl(path)) return path

  return `${process.env.APP_BASEPATH}${path}`
}

export const routeToExternalApp = (path: string) => {
  const url = getUrlToExternalApp(path)
  window.location.assign(url)
}
